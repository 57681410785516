<mo-loader color="primary" [loaded]="isLoaded">
    <div class="sign-in container-fluid container-padding">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <mat-card>
                    <h2 style="text-align: center">{{ "auth.pages.signIn.signIn" | translate}}</h2>
                    <form novalidate [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                        <mat-form-field>
                            <input matInput required formControlName="username"
                                   [placeholder]="'common.username' | translate"/>
                        </mat-form-field>
                        <mat-form-field>
                            <input type="password" matInput required formControlName="password"
                                   [placeholder]="'common.password' | translate"/>
                        </mat-form-field>
                        <div class="form-buttons">
                            <button mat-raised-button color="primary"
                                    type="submit">{{ "auth.pages.signIn.signIn" | translate}}
                            </button>
                        </div>
                        <mo-errors [errors]="errors"></mo-errors>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</mo-loader>
