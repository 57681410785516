export class Custom {
  public dateIssued: Date;

  constructor(custom: Custom = null) {

    const today = new Date();
    this.dateIssued = today;

    if (custom) {
      this.dateIssued = new Date(custom.dateIssued);
    }
  }

  private toTime(date: Date = null) {
    return date ? Math.round(date.getTime() / 1000) : null;
  }

  serialize(): any {
    return {
      dateIssued: this.toTime(this.dateIssued)
    };

  }


}


