import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private colors: string[] = [
    'rgb(244, 67, 54)',
    'rgb(233, 30, 99)',
    'rgb(156, 39, 176)',
    'rgb(103, 58, 183)',
    'rgb(63, 81, 181)',
    'rgb(33, 150, 243)',
    'rgb(3, 169, 244)',
    'rgb(0, 188, 212)',
    'rgb(0, 150, 136)',
    'rgb(76, 175, 80)',
    'rgb(139, 195, 74)',
    'rgb(205, 220, 57)',
    'rgb(255, 235, 59)',
    'rgb(255, 193, 7)',
    'rgb(255, 152, 0)',
    'rgb(255, 87, 34)',
    'rgb(121, 85, 72)',
    'rgb(158, 158, 158)',
    'rgb(96, 125, 139)',
    'rgb(81,77,91)',
    'rgb(193,68,46)',
    'rgb(131,160,166)',
    'rgb(246,205,55)',
    'rgb(170,170,170)',
    'rgb(55,121,224)',
    'rgb(36,152,232)',
    'rgb(198,211,222)',
    'rgb(245,98,98)',
    'rgb(232,61,61)',
    'rgb(40,132,189)',
    'rgb(15,172,176)',
    'rgb(105,105,105)',
    'rgb(201,201,201)',
    'rgb(26,191,170)',
    'rgb(124,71,71)',
    'rgb(83,124,74)',
    'rgb(206,124,124)',
    'rgb(86,107,131)',
    'rgb(131,208,132)'
  ];



  public getColors(): string[] {
    return this.colors;
  }

  constructor() {
  }
}
