import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: `<app-main></app-main>`
})
export class AppComponent {


  constructor(private translate: TranslateService) {
    translate.setDefaultLang('pl');
    translate.use('pl');
  }

}
//
// <h3>xd</h3>
//
// <button mat-raised-button color="primary" (click)="onSetTheme('default-theme')">Default</button>
//     <button mat-raised-button color="primary" (click)="onSetTheme('default-dark-theme')">Dark</button>
//     <button mat-raised-button color="primary" (click)="onSetTheme('dynamix-theme')">Light</button>
