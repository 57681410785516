import {SharedModule} from '../shared/shared.module';
import {ContactsListComponent} from './pages/contacts-list/contacts-list.component';
import {ContactModalComponent} from './components/contact-modal/contact-modal.component';
import {ContactLabelComponent} from './components/contact-label/contact-label.component';
import {NgModule} from '@angular/core';
import {ContactFormComponent} from './components/contact-form/contact-form.component';
import {ContactEditComponent} from 'src/app/contacts/pages/contact-edit.component';
import {ContactCreateComponent} from 'src/app/contacts/pages/contact-create.component';
import {ContactPickerComponent} from './components/contact-picker/contact-picker.component';
import {ContactMultiPickerComponent} from './components/contact-multi-picker/contact-multi-picker.component';
import {ContactLabelIdComponent} from './components/contact-label-id/contact-label-id.component';


@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ContactsListComponent,
    ContactModalComponent,
    ContactLabelComponent,
    ContactFormComponent,
    ContactEditComponent,
    ContactCreateComponent,
    ContactPickerComponent,
    ContactMultiPickerComponent,
    ContactLabelIdComponent,
  ],
  entryComponents: [
    ContactModalComponent,
  ],
  providers: [],
  exports: [
    ContactLabelIdComponent,
    ContactLabelComponent,
    ContactPickerComponent,
    ContactMultiPickerComponent,
  ],
})
export class ContactsModule {
}
