import {DocumentsState} from './documents.state';
import {DocumentsActions} from './documents.actions';

export function documentsReducer(state: DocumentsState = new DocumentsState(), action: any) {
  switch (action.type) {
    case DocumentsActions.UPDATE_FILTERS:
      return {...state, ...{filters: action.payload}};
    case DocumentsActions.UPDATE_CHANGES_HISTORY_FILTERS:
      return {...state, ...{changesHistoryFilters: action.payload}};
    default:
      return state;
  }
}

