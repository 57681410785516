<div class="container-fluid">
  <div class="row footer">
    <div class="col-xs-6" *ngIf="build$ | async as build">
      <p>API version: {{ build.api.version }} builded at: {{ build.api.buildedAt }}</p>
      <p>UI version: {{ build.ui.version }} builded at: {{ build.ui.buildedAt }} </p>
    </div>
    <div class="col-xs-6 right">
      <p> {{ (site$ | async).name }} {{ today | date: "yyyy"}}</p>
    </div>
  </div>
</div>
