import {AutocompleteInterface} from '../../shared/components/autocomplete/autocomplete.interface';

export class Tag implements AutocompleteInterface {
  public id: number;
  public order: number;
  public name: string;
  public parentId: number | null;
  public children: Tag[];


  constructor(tag: any = null) {
    this.id = 0;
    this.name = '';
    this.order = 0;
    this.parentId = 0;
    this.children = [];


    if (tag) {
      this.id = tag.id;
      this.name = tag.name;
      this.order = tag.order;
      this.parentId = tag.parentId;

      if(tag.children) {
        this.children = tag.children.map(tag => new Tag(tag));
      }
    }
  }

  serialize() {
    let data = <any>{};

    data.id = this.id;
    data.name = this.name;
    data.parentId = this.parentId;
    data.order = this.order;

    return data;
  }

  exists() {
    if (this.id && this.id > 0) {
      return true;
    }
  }

}
