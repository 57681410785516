import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthApiService} from '../../services/auth-api.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {FormComponent} from '../../../shared/components/form/form.component';
import {OnDestroy} from '@angular/core';
import {AuthActions} from '../../store/auth.actions';

@Component({
  selector: 'app-auth-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.less']
})
export class SignInComponent extends FormComponent implements OnInit, OnDestroy {

  constructor(private store: Store<AppState>, private api: AuthApiService, protected fb: FormBuilder) {
    super();

    this.formGroup = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

  }

  ngOnInit() {
    this.loaded();
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.load();

      this.addSubscription(this.api.signIn(this.preparePayload()).subscribe((res: Response) => {
        this.store.dispatch({type: AuthActions.SIGN_IN_SUCCESS, payload: {...res, 'redirect': true}});
        this.loaded();
      }, response => this.onError(response)));

    }
  }

  private preparePayload() {
    return this.formGroup.value;
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

}
