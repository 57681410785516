import {Contact} from '../../contacts/models';
import {Project} from '../../projects/models';
import {AutocompleteInterface} from '../../shared/components/autocomplete/autocomplete.interface';

export class Site implements AutocompleteInterface {
  public id: string;
  public name: string;
  public contact: Contact;
  public project: Project;
  public contactId: number;
  public projectId: number;
  public theme: string;

  constructor(site: any = null) {

    this.id = '';
    this.name = '';
    this.contact = new Contact();
    this.contactId = 0;
    this.projectId = 0;
    this.project = new Project();
    this.theme = 'dynamix';

    if (site) {
      this.id = site.id;
      this.name = site.name;
      this.contact = new Contact(site.contact);
      this.project = new Project(site.project);
      this.contactId = site.contactId;
      this.projectId = site.projectId;
      this.theme = site.theme;
    }
  }

  exists(): boolean {
    return !!this.id;
  }

  serialize(): any {
    return {
      pId: this.id,
      name: this.name,
      projectId: this.projectId,
      contactId: this.contactId,
      theme: this.theme
    };
  }
}
