import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {getSite} from '../../../auth/store/auth.selectors';
import {Site} from '../../../users/models';
import {getBuild} from '../../store/core.selectors';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent {

  public build$: Observable<any>;
  public today: Date;
  public site$: Observable<Site>;

  constructor(private store: Store<AppState>) {
    this.today = new Date();
    this.build$ = this.store.select(getBuild);
    this.site$ = this.store.select(getSite);
  }


}
