<mo-loader color="primary" [loaded]="isLoaded">
  <div class="sign-in container-fluid container-padding">
    <div class="row">
      <div class="col-md-4 col-md-offset-4">
        <mat-card>
          <h2 style="text-align: center">{{ "auth.pages.signUp.title" | translate}}</h2>
          <form novalidate [formGroup]="formGroup" (ngSubmit)="onSubmit()">
            <mat-form-field>
              <input moClearInput matInput required formControlName="firstName" [placeholder]="'common.firstName' | translate"/>
            </mat-form-field>
            <mat-form-field>
              <input moClearInput matInput required formControlName="lastName" [placeholder]="'common.lastName' | translate"/>
            </mat-form-field>
            <mat-form-field>
              <input moClearInput matInput required formControlName="email" [placeholder]="'common.email' | translate"/>
            </mat-form-field>
            <mat-form-field>
              <input moClearInput type="password" matInput required formControlName="password"
                     [placeholder]="'common.password' | translate"/>
            </mat-form-field>

            <mo-input-text [control]="formGroup.get('nip')" placeholder="contacts.common.nip"
                           [mask]="nipMask"></mo-input-text>

            <div class="form-buttons">
              <button mat-raised-button color="primary" type="submit">{{ "auth.pages.signUp.signUp" | translate}}
              </button>
            </div>
            <mo-errors [errors]="errors"></mo-errors>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
</mo-loader>


<!--//type="password"-->
