import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {ApiService} from '../../../shared/api.service';
import {FormComponent} from '../../../shared/components/form/form.component';
import {InputTextComponent} from '../../../shared/components/input-text/input-text.component';
import {AuthActions} from '../../store/auth.actions';

@Component({
  selector: 'dy-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.less']
})
export class SignUpComponent extends FormComponent implements OnInit {

  public nipMask: string = InputTextComponent.NIP_MASK;

  constructor(private store: Store<AppState>, private api: ApiService, protected fb: FormBuilder) {
    super();

    this.formGroup = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      nip: ['', [Validators.required]]
    });

  }

  ngOnInit() {
    this.loaded();
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.load();

      this.addSubscription(this.api.post('join', this.preparePayload()).subscribe((res: Response) => {
        this.store.dispatch({type: AuthActions.SIGN_IN_SUCCESS, payload: {...res, 'redirect': true}});
        this.loaded();
      }, response => this.onError(response)));

    }
  }

  private preparePayload() {
    return this.formGroup.value;
  }

  public ngOnDestroy() {
    this.unsubscribe();
  }

}
