import {OverlayContainer} from '@angular/cdk/overlay';
import {DOCUMENT} from '@angular/common';
import {Component, HostBinding, Inject} from '@angular/core';
import {Subscription} from 'rxjs';
import {Site} from '../../../users/models';
import {CoreActions} from '../../store/core.actions';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {getSite, isLogged} from '../../../auth/store/auth.selectors';
import {Observable} from 'rxjs/internal/Observable';
import {arePrefererencesLoaded} from '../../store/core.selectors';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LayoutActions} from '../../../layout/store/layout.actions';
import {filter, map, mergeMap} from 'rxjs/internal/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less'],
})
export class MainComponent {

  public isLogged$: Observable<boolean>;
  public isSideNavOpened$: Observable<boolean>;
  public isSideNavWithLabels: boolean = false;
  public arePrefererencesLoaded$: Observable<boolean>;
  public site: Site;

  @HostBinding('class') componentCssClass;

  constructor(@Inject(DOCUMENT) private document: Document,
              public overlayContainer: OverlayContainer,
              private store: Store<AppState>,
              private activatedRoute: ActivatedRoute,
              private matIconRegistry: MatIconRegistry,
              private router: Router,
              private sanitizer: DomSanitizer) {

    this.isLogged$ = this.store.select(isLogged);

    // @todo the fuck
    this.store.select(isLogged).subscribe((val) => {
      if (val) {
        this.store.dispatch({type: CoreActions.GET_PREFERENCES});
      }
    });

    this.store.select(getSite).subscribe(site => {
      this.site = site;
      this.matIconRegistry.addSvgIcon('dynamix', this.sanitizer.bypassSecurityTrustResourceUrl('assets/svg/dynamix.svg'));


      this.changeTheme(this.document.body, site.theme);
      this.changeTheme(this.overlayContainer.getContainerElement(), site.theme);
    });


    this.arePrefererencesLoaded$ = this.store.select(arePrefererencesLoaded);
    this.isSideNavOpened$ = this.store.select('layout', 'sideNavOpened');

    this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
    ).subscribe(event => {
      const title = `${event.title}`;
      this.store.dispatch({type: LayoutActions.SET_TILE, payload: {title}});
    });


  }

  private changeTheme(container: HTMLElement, theme: string): void {
    container.classList.remove('dynamix', 'deeppurple-amber', 'indigo-pink', 'pink-bluegrey', 'purple-green');

    container.classList.add(theme);

  }




}
