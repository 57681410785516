import {CoreActions} from '../../core/store/core.actions';
import {TagsState} from './tags.state';
import {TagsActions} from './tags.actions';

export function tagsReducer(state: TagsState = new TagsState(), action: any) {
  switch (action.type) {
    case CoreActions.SET_PREFERENCES:
      return {...state, ...action.payload.tags};
    case TagsActions.SET_TAGS:
      return {...state, ...{'items': action.payload}};
    case TagsActions.ADD_TAG:
      return {...state, ...{'items': [...state.items, action.payload]}};
    default:
      return state;
  }
}


