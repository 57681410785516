import {Component} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {AuthActions} from '../../../auth/store/auth.actions';
import {isLogged} from '../../../auth/store/auth.selectors';
import {LayoutActions} from '../../../layout/store/layout.actions';

@Component({
  selector: 'dy-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent {

  public isLogged$: Observable<boolean>;
  public firstName$: Observable<string>;
  public lastName$: Observable<string>;
  public title$: Observable<string>;

  constructor(private store: Store<AppState>) {
    this.isLogged$ = this.store.select(isLogged);
    this.title$ = this.store.select('layout', 'title');
    this.firstName$ = this.store.select('auth', 'user', 'firstName');
    this.lastName$ = this.store.select('auth', 'user', 'lastName');
  }

  public sideNavToggle() {
    this.store.dispatch({
      type: LayoutActions.SIDE_NAV_TOGGLE
    });
  }

  public signOut() {
    this.store.dispatch({
      type: AuthActions.SIGN_OUT,
      payload: {redirect: true}
    });
  }


}
