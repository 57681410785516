import {UsersActions} from './users.actions';
import {UsersState} from './users.state';

export function usersReducer(state: UsersState = new UsersState(), action: any) {
  switch (action.type) {
    case UsersActions.SET_USERS:
      return {...state, ...{'items': action.payload}};
    default:
      return state;
  }
}

