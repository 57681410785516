import {ActionReducerMap} from '@ngrx/store';

import {AppState} from './app.state';

import {authReducer as auth} from './auth/store/auth.reducer';
import {coreReducer as core} from './core/store/core.reducers';
import {projectsReducer as projects} from './projects/store/projects.reducer';
import {documentsReducer as documents} from './documents/documents.reducer';
import {contactsReducer as contacts} from './contacts/store/contacts.reducer';
import {layoutReducer as layout} from './layout/store/layout.reducer';
import {usersReducer as users} from './users/store/users.reducer';
import {tagsReducer as tags} from './tags-manager/store/tags.reducer';

export const reducers: ActionReducerMap<AppState> = { auth, core, projects, documents, contacts, layout, users, tags};
