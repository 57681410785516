import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'mo-input-text',
  template: `
      <mat-form-field>
          <input
                  *ngIf="!mask"
                  #element
                  type="text"
                  moClearInput
                  matInput
                  [formControl]="control"
                  [placeholder]="placeholder | translate"
                  [autofocus]="autofocus"
                  [required]="required"
                  (blur)="blur()"
          />
          <input
                  *ngIf="mask"
                  #element
                  type="text"
                  moClearInput
                  matInput
                  [formControl]="control"
                  [placeholder]="placeholder | translate"
                  [autofocus]="autofocus"
                  [required]="required"
                  [mask]="mask"
                  (blur)="blur()"
          />
          <span class="reset" matSuffix *ngIf="showReset()"><mat-icon (click)="reset()">clear</mat-icon></span>
      </mat-form-field>
  `,
})
export class InputTextComponent implements AfterViewInit {

  static readonly NIP_MASK = '000-00-00-000';
  static readonly POSTAL_CODE_MASK = '00-000';
  static readonly KRS_MASK = '000-00-00-000';
  static readonly BANK_ACCOUNT_MASK = '00 0000 0000 0000 0000 0000 0000';
  // static readonly DEFAULT_PATTERNS = {'all': { pattern: new RegExp('/*/')}};
  // public patterns = InputTextComponent.DEFAULT_PATTERNS;

  @ViewChild('element') nameInput: ElementRef;

  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() autofocus: boolean = false;
  @Input() required: boolean = false;
  @Input() mask: string = null;
  @Input() allowReset: boolean = false;
  @Output() onReset: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();

  ngAfterViewInit() {
    if (this.autofocus) {
      this.focus();
    }
  }

  private focus(): void {
    this.nameInput.nativeElement.focus();
  }

  public showReset(): boolean {
    return this.control.value && this.control.value.length > 0 && this.allowReset;
  }

  public reset(): void {
    this.onReset.emit(true);
  }

  public blur() {
    this.onBlur.emit();
  }


}
