import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ChartsModule} from 'ng2-charts';

import {LoaderComponent} from './components/loader/loader.component';
import {ErrorsComponent} from './components/errors/errors.component';
import {FabButtonComponent} from './components/fab-button/fab-button.component';
import {DeleteButtonDialogComponent} from './components/delete-button/delete-button-dialog.component';
import {DeleteButtonComponent} from './components/delete-button/delete-button.component';

import {TruncatePipe} from './pipes/truncate.pipe';
import {DateFormatPipe} from './pipes/date-format.pipe';

import {ClearInputDirective} from './directives/clear-input.directive';

import {MaterialModule} from './material/material.module';
import {NgxMaskModule} from 'ngx-mask';
import {TruncateModule} from 'ng2-truncate';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {InputMoneyComponent} from './components/input-money/input-money.component';
import {InputDateComponent} from './components/input-date/input-date.component';
import {InputTextComponent} from './components/input-text/input-text.component';
import {DateRangePickerComponent} from './components/date-range-picker/date-range-picker.component';
import {NgxMatDrpModule} from 'ngx-mat-daterange-picker';
import {AutocompleteComponent} from './components/autocomplete/autocomplete.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {TS_DATE_FORMATS, TsDateAdapter} from './date-adapter';
import {HttpErrorInterceptor} from './httpError.interceptor';
import { MultipleAutocompleteComponent } from './components/multiple-autocomplete/multiple-autocomplete.component';
import { NgMathPipesModule } from 'angular-pipes';
import { ProgressLoaderComponent } from './components/progress-loader/progress-loader.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    TruncateModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule,
    TranslateModule,
    NgxMatDrpModule,
    NgMathPipesModule,
    ChartsModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pl-PL'},
    {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'},
    {provide: MAT_DATE_FORMATS, useValue: TS_DATE_FORMATS},
    {provide: DateAdapter, useClass: TsDateAdapter},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    }
  ],
  exports: [
    FabButtonComponent,
    CommonModule,
    MaterialModule,
    LoaderComponent,
    ProgressLoaderComponent,
    ErrorsComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TruncatePipe,
    RouterModule,
    NgxMaskModule,
    DeleteButtonComponent,
    TranslateModule,
    ClearInputDirective,
    DateFormatPipe,
    InputMoneyComponent,
    InputDateComponent,
    InputTextComponent,
    DateRangePickerComponent,
    AutocompleteComponent,
    MultipleAutocompleteComponent,
    ChartsModule
  ],
  declarations: [
    LoaderComponent,
    ErrorsComponent,
    TruncatePipe,
    FabButtonComponent,
    DeleteButtonComponent,
    DeleteButtonDialogComponent,
    ClearInputDirective,
    DateFormatPipe,
    InputMoneyComponent,
    InputDateComponent,
    InputTextComponent,
    DateRangePickerComponent,
    AutocompleteComponent,
    MultipleAutocompleteComponent,
    ProgressLoaderComponent
  ],
  entryComponents: [
    DeleteButtonDialogComponent
  ]
})
export class SharedModule {
}
