import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {switchMap} from 'rxjs-compat/operator/switchMap';
import {TagsActions} from '../../tags-manager/store/tags.actions';
import {AuthApiService} from '../services/auth-api.service';
import {AuthActions} from './auth.actions';
import {Router} from '@angular/router';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';

@Injectable()
export class AuthEffects {

  constructor(private router: Router, private actions$: Actions, private api: AuthApiService) {
  }

  @Effect({dispatch: false})
  signOut$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_OUT),
    tap(() => this.router.navigate(['/auth/sign-in']))
  );

  @Effect({dispatch: false})
  signInSuccess$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_IN_SUCCESS),
    tap(() => this.router.navigate(['/dashboard']))
  );

  @Effect()
  reNewToken = this.actions$.pipe(
      ofType(AuthActions.RENEW_TOKEN),
      mergeMap(() =>
          this.api.reNew()
              .pipe(
                  map(payload => ({type: AuthActions.SET_AUTH, payload: payload})),
                  catchError(() => of({type: AuthActions.SIGN_OUT, payload: {redirect: true}}))
              )
      )
  );


}
