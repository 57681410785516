import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {CoreActions} from './core.actions';
import {ApiService} from '../../shared/api.service';
import {AuthActions} from '../../auth/store/auth.actions';
import {catchError, map, mergeMap} from 'rxjs/internal/operators';
import {of} from 'rxjs/index';
import {ContactsActions} from '../../contacts/store/contacts.actions';
import {ProjectsActions} from '../../projects/store/projects.actions';
import {TagsActions} from '../../tags-manager/store/tags.actions';

@Injectable()
export class CoreEffects {

  @Effect()
  getPreferences$: Observable<Action> = this.actions$.pipe(
    ofType(CoreActions.GET_PREFERENCES),
    mergeMap(action =>
      this.api.get(`preferences`)
        .pipe(
          map(payload => ({type: CoreActions.SET_PREFERENCES, payload: payload})),
          catchError(() => of({type: AuthActions.SIGN_OUT, payload: {redirect: true}}))
        )
    )
  );

  @Effect()
  getContacts: Observable<Action> = this.actions$.pipe(
    ofType(CoreActions.GET_CONTACTS),
    mergeMap(action =>
      this.api.get(`contacts`)
        .pipe(
          map(payload => ({type: ContactsActions.SET_CONTACTS, payload: payload})),
          catchError(() => of({type: AuthActions.SIGN_OUT, payload: {redirect: true}}))
        )
    )
  );

  @Effect()
  getProjects: Observable<Action> = this.actions$.pipe(
    ofType(CoreActions.GET_PROJECTS),
    mergeMap(action =>
      this.api.get(`projects`)
        .pipe(
          map(payload => ({type: ProjectsActions.SET_PROJECTS, payload: payload})),
          catchError(() => of({type: AuthActions.SIGN_OUT, payload: {redirect: true}}))
        )
    )
  );


  @Effect()
  getTags: Observable<Action> = this.actions$.pipe(
    ofType(CoreActions.GET_TAGS),
    mergeMap(action =>
      this.api.get(`tags`)
        .pipe(
          map(payload => ({type: TagsActions.SET_TAGS, payload: payload})),
          catchError(() => of({type: AuthActions.SIGN_OUT, payload: {redirect: true}}))
        )
    )
  );

  constructor(private actions$: Actions,
              private api: ApiService) {
  }


}
