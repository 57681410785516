import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Observable} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private baseUrl: String = environment.apiUrl;
  protected token: string = null;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select('auth').subscribe((auth: any) => this.token = auth.token);
  }

  public authorizationToken() {
    return `Bearer ${this.token}`;
  }

  private getOptions(): any {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    };
  }

  public checkBankAccountNumber(bankAccountNumber: string) {

    const now = new Date();
    const dateString = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;

    return this.http.get(`https://wl-api.mf.gov.pl/api/search/bank-account/${bankAccountNumber}?date=${dateString}`);
  }

  public download(endpoint: string, params: Object = {}, type: string = 'application/download'): Observable<any> {

    const options = <any>{
      responseType: 'blob',
      headers: new HttpHeaders()
        .set('Content-Type', type)
        .set('Accept', type)
    };

    const apiUrl = this.generateUrl(endpoint, params);

    return this.http.get(apiUrl, {...options, ...{params}});

  }

  public downloadFile(data: Blob, name: string, ext: string) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = `${name}.${ext}`;

    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public get(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.get(apiUrl, {...options, ...{params}});
  }

  public delete(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    options.body = params;
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.delete(apiUrl, options);
  }

  public put(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.put(apiUrl, params, options);
  }

  public patch(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.patch(apiUrl, params, options);
  }

  public post(endpoint: string, params: Object = {}): Observable<any> {
    const options = this.getOptions();
    const apiUrl = this.generateUrl(endpoint, params);
    return this.http.post(apiUrl, params, options);
  }

  public generateUrl(endpoint: string, params: Object = {}): string {
    // return `${this.baseUrl}/${endpoint}?XDEBUG_PROFILE=1`;
    return `${this.baseUrl}/${endpoint}`;
  }

}
