import {OverlayModule} from '@angular/cdk/overlay';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { DeviceDetectorModule } from 'ngx-device-detector';



import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';

registerLocaleData(localePl, 'pl-PL');

import {localStorageSync} from 'ngrx-store-localstorage';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['auth', 'layout', 'documents'], rehydrate: true})(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

import {reducers} from './app.reducers';
import {AuthEffects} from './auth/store/auth.effect';

import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {AppRoutingModule} from './app.router';
import {AppComponent} from './app.component';
import {CoreEffects} from './core/store/core.effects';

import {AuthModule} from './auth/auth.module';
import {CoreModule} from './core/core.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {LayoutEffects} from './layout/store/layout.effects';
import {NgxMaskModule} from 'ngx-mask';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

const APP_NAME = 'app-ui';
const DEFAULT_LANGUAGE = 'pl-PL';

const MODULES = [
  AuthModule,
  DashboardModule,
  CoreModule
];

export function createTranslateLoader(http: HttpClient) {
  // @todo cache booster https://github.com/ngx-translate/http-loader/issues/25#issuecomment-339261246
  const dateTime = new Date().getTime();
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?cb=${dateTime}`);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ...MODULES,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    BrowserModule.withServerTransition({appId: APP_NAME}),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([
      AuthEffects,
      LayoutEffects,
      CoreEffects
    ]),
    StoreDevtoolsModule.instrument(),
    OverlayModule
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {
}

