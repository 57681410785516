export class BalanceReport {
  public month: string;
  public year: string;

  constructor(balanceReport: BalanceReport = null) {

    this.month = '09';
    this.year = '2018';

    if (balanceReport) {
      this.month = balanceReport.month;
      this.year = balanceReport.year;
    }
  }

  serialize(): any {
    return {
      month: this.month,
      year: this.year
    };

  }

}


