import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MoneyService {

  constructor() {

  }

  static netToGross(vat: number, amount: number): number {
    return this.round(amount * (1 + vat));
  }

  static round(amount: number): number {
    return this.roundTo(amount, 2);
  }

  static roundTo(n, digits): number {
    let negative = false;
    if (digits === undefined) {
      digits = 0;
    }
    if (n < 0) {
      negative = true;
      n = n * -1;
    }
    const multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(2);
    if (negative) {
      n = (n * -1).toFixed(2);
    }
    return parseFloat(n);
  }


}
