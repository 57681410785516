import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[moClearInput]'
})
export class ClearInputDirective {

  constructor(public renderer: Renderer2, public el: ElementRef) {
  }

  ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-lpignore', 'true');
    this.renderer.setAttribute(this.el.nativeElement, 'spellcheck', 'false');
    this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'off');
    this.renderer.setAttribute(this.el.nativeElement, 'autocorrect', 'off');
  }

}
