import {Contact} from '../../contacts/models/contact.model';
import {AutocompleteInterface} from '../../shared/components/autocomplete/autocomplete.interface';


export class Project implements AutocompleteInterface {
  public id: number;
  public name: string;
  public contactId: number;
  public contact?: Contact;
  public color: string;

  constructor(project: any = null) {
    this.id = 0;
    this.name = '';
    this.contactId = 0;
    this.color = 'rgb(244, 67, 54)';
    this.contact = new Contact();

    if (project) {
      this.id = project.id;
      this.name = project.name;
      this.contactId = project.contactId;
      this.color = project.color;

      if (project.contact) {
        this.contact = new Contact(project.contact);
      }
    }
  }

  exists(): boolean {
    return (this.id && this.id !== 0);
  }

  serialize(): any {
    return {
      id: this.id,
      name: this.name,
      color: this.color,
      contactId: this.contactId
    };
  }

}



