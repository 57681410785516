import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AppState} from '../../../app.state';
import {AuthActions} from '../../../auth/store/auth.actions';
import {getSite, getSites} from '../../../auth/store/auth.selectors';
import {FormComponent} from '../../../shared/components/form/form.component';
import {Site} from '../../../users/models';
import {CoreActions} from '../../store/core.actions';

@Component({
  selector: 'dy-site-picker',
  template: `
      <mat-form-field *ngIf="sites.length > 1">
          <mat-select required
                      [placeholder]="'common.site' | translate"
                      (selectionChange)="onSiteChanged($event)"
                      [ngModel]="(site$ | async).id">
              <mat-option *ngFor="let site of sites" [value]="site.id">
                  {{ site.name }}
              </mat-option>
          </mat-select>
      </mat-form-field>
  `,
})
export class SitePickerComponent extends FormComponent {

  public sites: Site[] = [];
  public site$: Observable<Site>;

  @Input() allowEmptyValue: Boolean = false;
  @Input() required: Boolean = false;
  @Input() disabled: Boolean = false;
  @Input() placeholder: string = 'common.site';

  constructor(private store: Store<AppState>, public dialog: MatDialog) {
    super();
    this.store.select(getSites).subscribe((sites: Site[]) => this.sites = sites);
    this.site$ = this.store.select(getSite);
  }

  onSiteChanged(selectChange: MatSelectChange) {
    const site = this.sites.find((s: Site) => s.id === selectChange.value);
    this.store.dispatch({type: AuthActions.SET_SITE, payload: {site}});
    this.store.dispatch({type: CoreActions.GET_PREFERENCES});
    this.loaded();
  }


}
