import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {ProjectPickerComponent} from './components/project-picker/project-picker.component';
import {ProjectsListComponent} from './pages/projects-list/projects-list.component';
import {ContactsModule} from '../contacts/contacts.module';
import {ProjectLabelComponent} from './components/project-label/project-label.component';
import {ProjectService} from './project.service';
import {ProjectFormComponent} from './components/project-form/project-form.component';
import {ProjectEditComponent} from './pages/project-edit.component';
import {ProjectCreateComponent} from './pages/project-create.component';
import {ProjectMultiPickerComponent} from './components/project-multi-picker/project-multi-picker.component';


@NgModule({
  imports: [
    SharedModule,
    ContactsModule
  ],
  declarations: [
    ProjectsListComponent,
    ProjectPickerComponent,
    ProjectLabelComponent,
    ProjectFormComponent,
    ProjectCreateComponent,
    ProjectEditComponent,
    ProjectMultiPickerComponent
  ],
  entryComponents: [],
  providers: [
    ProjectService
  ],
  exports: [
    ProjectsListComponent,
    ProjectPickerComponent,
    ProjectLabelComponent,
    ProjectMultiPickerComponent
  ]
})
export class ProjectsModule {
}
