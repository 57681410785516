export class ReInvoicePosition {
  public documentId: number;
  public companyName: string;
  public documentName: string;
  public net: number;
  public gross: number;
  public dateIssued: Date;

  constructor(reInvoicePosition: ReInvoicePosition = null) {

    const today = new Date();

    this.documentId = 0;
    this.companyName = '';
    this.documentName = '';
    this.net = 0;
    this.gross = 0;
    this.dateIssued = today;

    if (reInvoicePosition) {
      this.documentId = reInvoicePosition.documentId;
      this.companyName = reInvoicePosition.companyName;
      this.documentName = reInvoicePosition.documentName;
      this.net = reInvoicePosition.net;
      this.gross = reInvoicePosition.gross;
      this.dateIssued = new Date(reInvoicePosition.dateIssued);
    }
  }

  private toTime(date: Date = null) {
    return date ? Math.round(date.getTime() / 1000) : null;
  }

  update(position: ReInvoicePosition) {
    const newPos = Object.assign(this, position);
    this.companyName = newPos.companyName;
    this.documentName = newPos.documentName;
    this.setNet(newPos.net);
    this.setGross(newPos.gross);
    this.dateIssued = newPos.dateIssued;
  }

  setNet(net: number) {
    this.net = net;
  }

  setGross(gross: number) {
    this.gross = gross;
  }

  toFloat(value: number): number {
    return Number.parseFloat(value.toFixed(2));
  }

  serialize(): any {
    return {
      documentId: this.documentId,
      companyName: this.companyName,
      documentName: this.documentName,
      net: this.net,
      gross: this.gross,
      dateIssued: this.toTime(this.dateIssued)
    };

  }

}


