<mo-loader color="primary"
           [loaded]="((isLogged$ | async) && (arePrefererencesLoaded$ | async)) || !(isLogged$ | async)">


  <mat-drawer-container class="cont">
    <mat-drawer #drawer class="sidenav" mode="side" [opened]="(isSideNavOpened$ | async) && (isLogged$ | async)"
                [ngClass]="{'hideLabels': isSideNavWithLabels}">

      <app-sidebar></app-sidebar>

    </mat-drawer>

    <dy-header></dy-header>

    <mat-divider></mat-divider>

    <div class="example-sidenav-content">
      <router-outlet></router-outlet>
    </div>

    <app-footer *ngIf="(isLogged$ | async)"></app-footer>

  </mat-drawer-container>

</mo-loader>

