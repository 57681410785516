export class DocumentType {

  static readonly INCOME_INVOICE = 'INCOME_INVOICE';
  static readonly RE_INVOICE = 'RE_INVOICE';
  static readonly BALANCE_REPORT = 'BALANCE_REPORT';
  static readonly CASH_DEPOSIT_CASH_WITHDRAW = 'CASH_DEPOSIT_CASH_WITHDRAW';
  static readonly COST_INVOICE = 'COST_INVOICE';
  static readonly CUSTOM_COST_DOCUMENT = 'CUSTOM_COST_DOCUMENT';
  static readonly INCOME_INVOICE_CORRECTION = 'INCOME_INVOICE_CORRECTION';
  static readonly CUSTOM = 'CUSTOM';

  public name: string;
  public label: string;
  public isGenerated: boolean;
  public isUploadAllowed: boolean;
  public hasMeta: boolean;
  public shortName: string;

  constructor(documentType: any = null) {
    this.name = '';
    this.label = '';
    this.isGenerated = false;
    this.isUploadAllowed = false;
    this.hasMeta = false;
    this.shortName = '';


    if (documentType) {
      this.name = documentType.name;
      this.label = documentType.label;
      this.isGenerated = documentType.isGenerated;
      this.isUploadAllowed = documentType.isUploadAllowed;
      this.hasMeta = documentType.hasMeta;
      this.shortName = documentType.shortName;
    }
  }


}

// @todo ENUM

// enum DocumentTypeName {
//
//  INCOME_INVOICE = 'INCOME_INVOICE',
//  RE_INVOICE = 'RE_INVOICE',
//  BALANCE_REPORT = 'BALANCE_REPORT',
//  CASH_DEPOSIT_CASH_WITHDRAW = 'CASH_DEPOSIT_CASH_WITHDRAW',
//  COST_INVOICE = 'COST_INVOICE',
//  CUSTOM_COST_DOCUMENT = 'CUSTOM_COST_DOCUMENT',
//  INCOME_INVOICE_CORRECTION = 'INCOME_INVOICE_CORRECTION',
//  CUSTOM = 'CUSTOM'
// }
