import {ReInvoicePosition} from './reInvoicePosition.model';

export class ReInvoice {
  public positions: ReInvoicePosition[];

  constructor(reInvoice: any = null) {

    this.positions = [
      new ReInvoicePosition()
    ];

    if (reInvoice) {
      this.positions = reInvoice.positions;
    }
  }

  serialize(): any {
    return {
      positions: this.positions.map((position: ReInvoicePosition) => position.serialize())
    };

  }

  addNewPosition() {
    this.positions.push(new ReInvoicePosition());
  }

  setPositions(positions: ReInvoicePosition[]) {
    this.positions = positions;
  }

}


