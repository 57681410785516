import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../shared/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private api: ApiService) {
  }


  public signIn(payload: any): Observable<any> {
    return this.api.post(`auth`, payload);
  }

  public signUp(payload: any): Observable<any> {
    return this.api.post(`join`, payload);
  }

  public reNew(): Observable<any> {
    return this.api.get(`authStatus`);
  }

}
