import {Validators} from '@angular/forms';
import {InvoicePosition} from './invoicePosition.model';

export class Invoice {
  public id: number;
  public documentId: number;
  public net: number;
  public gross: number;
  public dateIssued: Date;
  public datePayment: Date;
  public dateSold: Date;
  public dateCorrected: Date;
  public datePayed: Date;
  public correctedId: number;
  public isPayed: boolean;
  public hasProforma: boolean;
  public bankAccountNumber: number;
  public paymentMethod: string;
  public paymentDaysLeft: number;
  public paymentDaysLeftStatus: string;
  public paymentMethodObject: any;
  public positions: InvoicePosition[];

  constructor(invoice: any = null) {

    const today = new Date();

    const datePayment = new Date();
    datePayment.setDate(datePayment.getDate() + 7);

    this.id = 0;
    this.documentId = 0;
    this.net = 0;
    this.gross = 0;
    this.dateIssued = today;
    this.datePayment = datePayment;
    this.dateSold = today;
    this.dateCorrected = today;
    this.datePayed = today;
    this.correctedId = 0;
    this.isPayed = false;
    this.hasProforma = false;
    this.bankAccountNumber = 0;
    this.paymentMethod = 'TRANSFER';
    this.paymentMethodObject = null;
    this.paymentDaysLeft = 0;
    this.positions = [
      new InvoicePosition()
    ];
    this.paymentDaysLeftStatus = null;

    if (invoice) {
      this.id = invoice.id;
      this.documentId = invoice.documentId;
      this.net = invoice.net;
      this.gross = invoice.gross;
      this.dateIssued = new Date(invoice.dateIssued);
      this.datePayment = new Date(invoice.datePayment);
      this.dateSold = new Date(invoice.dateSold);
      this.datePayed = new Date(invoice.datePayed);
      this.dateCorrected = new Date(invoice.dateCorrected);
      this.isPayed = invoice.isPayed;
      this.correctedId = invoice.correctedId;
      this.hasProforma = invoice.hasProforma;
      this.bankAccountNumber = invoice.bankAccountNumber;
      this.paymentMethod = invoice.paymentMethod;
      this.paymentMethodObject = invoice.paymentMethodObject;
      this.positions = invoice.positions ? invoice.positions.map((pos: any) => new InvoicePosition(pos)) : [];
      this.paymentDaysLeft = invoice.paymentDaysLeft;
      this.paymentDaysLeftStatus = invoice.paymentDaysLeftStatus;
    }
  }


  // czy to jest potrzebne
  private toTime(date: Date = null) {
    return date ? Math.round(date.getTime() / 1000) : null;
  }

  serialize(): any {
    return {
      id: this.id,
      documentId: this.documentId,
      net: this.net,
      gross: this.gross,
      dateIssued: this.toTime(this.dateIssued),
      datePayment: this.toTime(this.datePayment),
      dateSold: this.toTime(this.dateSold),
      dateCorrected: this.toTime(this.dateCorrected),
      datePayed: this.toTime(this.datePayed),
      correctedId: this.correctedId,
      isPayed: this.isPayed,
      hasProforma: this.hasProforma,
      bankAccountNumber: this.bankAccountNumber,
      paymentMethod: this.paymentMethod,
      positions: this.positions ? this.positions.map((pos: InvoicePosition) => pos.serialize()) : []
    };

  }

  addNewPosition() {
    this.positions.push(new InvoicePosition());
  }

  setPositions(positions: InvoicePosition[]) {
    this.positions = positions;
  }


  calculate() {
    console.log(this.positions);
    if (this.positions.length > 0 && this.positions[0] && this.positions[0].valueGross !== 0) {
      this.net = 0;
      this.gross = 0;
      this.positions.forEach((position: InvoicePosition) => {
        this.net += position.valueNet;
        this.gross += position.valueGross;
      });
    }
  }

}


