import {createSelector} from '@ngrx/store';
import {AppState} from '../../app.state';
import {Site} from '../../users/models';
import {AuthState} from './auth.state';

export const authObj = (appState: AppState) => appState.auth;

export const isLogged = createSelector(authObj, (auth: any) => (auth && auth.token));

export const getSites = createSelector(authObj, (auth: any) => (auth && auth.user) ? auth.user.sites.map(site => new Site(site)) : []);
export const getSite = createSelector(authObj, (auth: any) => (auth && auth.site) ? new Site(auth.site) : new Site());

export const getToken = createSelector(authObj, (auth: any) => (auth && auth.token) ? auth.token : "");

function hasScope(auth: AuthState, scopeName: string): boolean {
  return !!(auth && auth.token && auth.user && auth.user.scope && auth.user.scope.find(scope => scope === scopeName));
}

export const hasProjectsScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'PROJECTS'));
export const hasDocumentsScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'DOCUMENTS'));
export const hasContactsScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'CONTACTS'));
export const hasBalanceScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'BALANCE'));
export const hasUsersScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'USERS'));
export const hasLogsScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'LOGS'));
export const hasTagsScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'TAGS_MANAGER'));
export const hasSiteScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'SITE'));
export const hasHistoryChangesScope = createSelector(authObj, (auth: AuthState) => hasScope(auth, 'HISTORY_CHANGES'));


export const isAdmin = createSelector(authObj, (auth: AuthState) => (auth.user.firstName === 'Robert'));
