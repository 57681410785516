import {MoneyService} from '../../shared/money/money.service';

export class InvoicePosition {
  public id: number;
  public documentInvoiceId: number;
  public name: string;
  public quantity: number;
  public net: number;
  public valueNet: number;
  public vat: number;
  public vatValue: number; // dodac
  public valueGross: number;


  public calculate() {

    let vat = this.vat;
    if (vat.toString() === 'nd' || vat.toString() === 'oo') {
      vat = 0;
    }

    this.valueNet = this.quantity * this.net;
    this.valueGross = MoneyService.netToGross(vat, this.valueNet);
    this.vatValue = this.valueGross - this.valueNet;
  }

  public serialize() {
    return {
      id: this.id,
      name: this.name,
      quantity: this.quantity,
      net: this.net,
      vat: this.vat,
    };
  }

  constructor(invoicePosition: InvoicePosition = null) {
    this.id = 0;
    this.documentInvoiceId = 0;
    this.name = '';
    this.quantity = 1;
    this.net = 0;
    this.valueNet = 0;
    this.vat = 0.23;
    this.vatValue = 0;
    this.valueGross = 0;


    if (invoicePosition) {
      this.id = invoicePosition.id;
      this.documentInvoiceId = invoicePosition.documentInvoiceId;
      this.name = invoicePosition.name;
      this.setQuantity(invoicePosition.quantity);
      this.setNet(invoicePosition.net);
      this.valueNet = invoicePosition.valueNet;
      this.vat = invoicePosition.vat;
      this.vatValue = invoicePosition.vatValue;
      this.valueGross = invoicePosition.valueGross;
      this.calculate();
    }

  }

  update(position: InvoicePosition) {
    console.log('update', position);
    const newPos = Object.assign(this, position);
    this.id = newPos.id;
    this.documentInvoiceId = newPos.documentInvoiceId;
    this.name = newPos.name;
    this.setQuantity(newPos.quantity);
    this.setNet(newPos.net);
    this.valueNet = newPos.valueNet;
    this.vat = newPos.vat;
    this.vatValue = newPos.vatValue;
    this.valueGross = newPos.valueGross;
  }


  setNet(value: number) {
    this.net = value;
  }

  setQuantity(value: number) {
    this.quantity = value;
  }

}

