import {ProjectsState} from './projects.state';
import {ProjectsActions} from './projects.actions';
import {CoreActions} from '../../core/store/core.actions';

export function projectsReducer(state: ProjectsState = new ProjectsState(), action: any) {
  switch (action.type) {
    case CoreActions.SET_PREFERENCES:
      return {...state, ...action.payload.projects};
    case ProjectsActions.SET_PROJECTS:
      return {...state, ...{'items': action.payload}};
    default:
      return state;
  }
}


