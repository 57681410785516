export class Pagination {
  public count: number;
  public currentPage: number;
  public limit: number;
  public prevPage: number;
  public nextPage: number;
  public offset: number;
  public pages: number;

  constructor(pagination: any = null) {

    this.count = 0;
    this.currentPage = 0;
    this.limit = 0;
    this.prevPage = 0;
    this.nextPage = 0;
    this.offset = 0;
    this.pages = 0;

    if (pagination) {
      this.count = pagination.count;
      this.currentPage = pagination.currentPage;
      this.limit = pagination.limit;
      this.prevPage = pagination.prevPage;
      this.nextPage = pagination.nextPage;
      this.offset = pagination.offset;
      this.pages = pagination.pages;
    }
  }
}


