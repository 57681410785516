import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from './core/guards/auth.guard';
import {DashboardComponent} from './dashboard/dashboard.component';

const routes: Routes = [
  {path: 'dashboard', data: {title: 'Start'}, component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'balance', loadChildren: () => import('./balance/balance.module').then(m => m.BalanceModule)},
  {path: 'documents', loadChildren: () => import('./documents/documents.routing.module').then(m => m.DocumentsRoutingModule)},
  {path: 'projects', loadChildren: () => import('./projects/projects.routing.module').then(m => m.ProjectsRoutingModule)},
  {path: 'sites', loadChildren: () => import('./site/sites.routing.module').then(m => m.SitesRoutingModule)},
  {path: 'tags', loadChildren: () => import('./tags-manager/tags.routing.module').then(m => m.TagsRoutingModule)},
  {path: 'logs', loadChildren: () => import('./logs/logs.routing.module').then(m => m.LogsRoutingModule)},
  {path: 'changes-history', loadChildren: () => import('./changes-history/changes-history.routing.module').then(m => m.ChangesHistoryRoutingModule)},
  {path: 'contacts', loadChildren: () => import('./contacts/contacts.routing.module').then(m => m.ContactsRoutingModule)},
  {path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule)},
  {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
