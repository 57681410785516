import {NgModule} from '@angular/core';
import {ContactsModule} from '../contacts/contacts.module';
import {SharedModule} from '../shared/shared.module';
import {DashboardComponent} from './dashboard.component';
import {ProjectsModule} from '../projects/projects.module';

@NgModule({
  imports: [
    SharedModule,
    ProjectsModule,
    ContactsModule,
  ],
  declarations: [
    DashboardComponent,
  ],
  entryComponents: [],
  providers: [],
  exports: [
    DashboardComponent,
  ],
})
export class DashboardModule {
}
