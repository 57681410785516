import {createSelector} from '@ngrx/store';
import {AppState} from '../../app.state';
import {CoreState} from './core.state';
import {DocumentType} from '../../documents/models/index';

const coreObj = (appState: AppState) => appState.core;

export const getVatTypes = createSelector(coreObj, (store: CoreState) => vatTypes(store));
export const getBuild = createSelector(coreObj, (store: CoreState) => build(store));
export const getScopes = createSelector(coreObj, (store: CoreState) => scopes(store));
export const getDocumentTypes = createSelector(coreObj, (store: CoreState) => documentTypes(store));
export const arePrefererencesLoaded = createSelector(coreObj, (store: CoreState) => !!(store.preferences && store.preferences.documentTypes));

function build(store: CoreState) {
  return (store.preferences && store.preferences.build) ? store.preferences.build : null;
}

function scopes(store: CoreState) {
  return (store.preferences && store.preferences.scopes) ? store.preferences.scopes : [];
}

function documentTypes(store: CoreState) {
  return (store.preferences && store.preferences.documentTypes) ? store.preferences.documentTypes.map((documentType) => new DocumentType(documentType)) : [];
}

function paymentMethods(store: CoreState) {
  return (store.preferences && store.preferences.paymentMethods) ? store.preferences.paymentMethods : [];
}

export const getPaymentMethods = createSelector(coreObj, (store: CoreState) => paymentMethods(store));

function vatTypes(store: CoreState) {
  return (store.preferences && store.preferences.vatTypes) ? store.preferences.vatTypes : [];
}


