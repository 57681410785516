import {Contact} from '../models';
import {ContactsState} from './contacts.state';
import {ContactsActions} from './contacts.actions';
import {CoreActions} from '../../core/store/core.actions';

export function contactsReducer(state: ContactsState = new ContactsState(), action: any) {
  switch (action.type) {
    case CoreActions.SET_PREFERENCES:
      return {...state, ...action.payload.contacts};
    case ContactsActions.SET_CONTACTS:
      return {...state, ...{'items': action.payload}};
    case ContactsActions.ADD_CONTACTS:
      return {...state, ...{'items': [...state.items, action.payload]}};
    case ContactsActions.UPDATE_CONTACT:
      const contact = action.payload;
      const contactIndex = state.items.findIndex((con: Contact) => con.id === contact.id);
      const items = state.items;

      items[contactIndex] = contact;
      return {...state, ...{items}};
    default:
      return state;
  }
}

