import {Site} from './site.model';

export class User {
  public id: number;
  public username: string;
  public password: string;
  public firstName: string;
  public lastName: string;
  public scope: string[];
  public scopeJoined?: string;
  public sites: Site[];

  constructor(user: any = null) {

    this.id = 0;
    this.username = '';
    this.password = '';
    this.firstName = '';
    this.lastName = '';
    this.scope = [];
    this.sites = [];

    if (user) {
      this.id = user.id;
      this.username = user.username;
      this.password = user.password;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.scope = user.scope;
      this.scopeJoined = user.scope.join(', ');
      this.sites = user.sites.map((site) => new Site(site));
    }
  }

  exists(): boolean {
    return (this.id && this.id !== 0);
  }

  serialize(): any {
    return {
      id: this.id,
      username: this.username,
      password: this.password,
      firstName: this.firstName,
      lastName: this.lastName,
      scope: this.scope,
    };
  }
}
