import {NetGross} from './netGross.model';
import {Document} from './document.model';

export class FinancialSummary {
  public cost: NetGross;
  public income: NetGross;
  public profit: NetGross;
  public notPayedIncomeInvoices: number;
  public notPayedCostInvoices: number;

  constructor(financialSummary: any = null) {
    this.cost = new NetGross();
    this.income = new NetGross();
    this.profit = new NetGross();

    this.notPayedCostInvoices = 0;
    this.notPayedIncomeInvoices = 0;

    if (financialSummary) {
      this.cost = new NetGross(financialSummary.cost);
      this.income = new NetGross(financialSummary.income);
      this.profit = new NetGross(financialSummary.profit);
      this.notPayedCostInvoices = financialSummary.notPayedCostInvoices;
      this.notPayedIncomeInvoices = financialSummary.notPayedIncomeInvoices;
    }
  }

  public fromDocuments(documents: Document[]) {

    documents.map( (document: Document) => {

      if (document.hasMeta()) {
        if (document.isCostInvoice()) {
          const invoice = document.getInvoice();

          if (!invoice.hasProforma) {
            this.cost.net += invoice.net;
            this.cost.gross += invoice.gross;

            if (!invoice.isPayed) {
              this.notPayedCostInvoices += invoice.gross;
            }
          }
        }

        if (document.isIncomeInvoice() || document.isCorrectionInvoice()) {

          const invoice = document.getInvoice();

          this.income.net += invoice.net;
          this.income.gross += invoice.gross;

          if (!invoice.isPayed) {
            this.notPayedIncomeInvoices += invoice.gross;
          }
        }
      }

      this.profit.net = this.income.net - this.cost.net;
      this.profit.gross = this.income.gross - this.cost.gross;

    });

  }

}




