import {LayoutActions} from './layout.actions';
import {LayoutState} from './layout.state';
import {AuthActions} from '../../auth/store/auth.actions';

export function layoutReducer(state: LayoutState = new LayoutState(), action: any) {
  switch (action.type) {
    case LayoutActions.SET_TILE:
      return {...state, ...action.payload};
    case AuthActions.SIGN_IN_SUCCESS:
      return {...state, ...{sideNavOpened: true}};
    case AuthActions.SIGN_OUT:
      return {...state, ...{sideNavOpened: false}};
    case LayoutActions.SIDE_NAV_TOGGLE:
      return {...state, ...{sideNavOpened: !state.sideNavOpened}};
    default:
      return state;
  }
}
