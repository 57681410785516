import {AutocompleteInterface} from '../../shared/components/autocomplete/autocomplete.interface';

export class Contact implements AutocompleteInterface {
  public id: number;
  public name: string;
  public fullName: string;
  public email: string;
  public www: string;
  public phone: string;
  public nip: string;
  public street: string;
  public city: string;
  public postalCode: string;
  public comment: string;
  public bankAccountNumber: string;
  public trustBankAccountNumber: string;
  public krs: string;

  constructor(contact: any = null) {
    this.id = 0;
    this.name = '';
    this.fullName = '';
    this.email = '';
    this.www = '';
    this.phone = '';
    this.nip = '';
    this.street = '';
    this.city = '';
    this.postalCode = '';
    this.comment = '';
    this.bankAccountNumber = '';
    this.trustBankAccountNumber = '';
    this.krs = '';

    if (contact) {
      this.id = contact.id;
      this.name = contact.name;
      this.fullName = contact.fullName;
      this.email = contact.email;
      this.www = contact.www;
      this.phone = contact.phone;
      this.nip = contact.nip;
      this.street = contact.street;
      this.city = contact.city;
      this.postalCode = contact.postalCode;
      this.comment = contact.comment;
      this.bankAccountNumber = contact.bankAccountNumber;
      this.trustBankAccountNumber = contact.trustBankAccountNumber;
      this.krs = contact.krs;

    }
  }

  serialize() {
    let data = <any>{};


    data.id = this.id;
    data.name = this.name;
    data.fullName = this.fullName;
    data.email = this.email;
    data.www = this.www;
    data.phone = this.phone;
    data.nip = this.nip;
    data.street = this.street;
    data.city = this.city;
    data.postalCode = this.postalCode;
    data.comment = this.comment;
    data.bankAccountNumber = this.bankAccountNumber;
    data.trustBankAccountNumber = this.trustBankAccountNumber;
    data.krs = this.krs;

    return data;
  }

  exists() {
    if (this.id && this.id > 0) {
      return true;
    }
  }

}
