import {CoreActions} from './core.actions';
import {CoreState} from './core.state';

export function coreReducer(state: CoreState = new CoreState(), action: any) {
  switch (action.type) {
    case CoreActions.SET_PREFERENCES:
      const preferences = action.payload;
      return {...state, ...{preferences}};
    default:
      return state;
  }
}
