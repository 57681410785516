<mat-toolbar color="default" *ngIf="(isLogged$ | async)">
  <div class="row">
    <div class="col-xs-8 left">
      <div style="    display: block;
    float: left;">
        <button mat-icon-button (click)="sideNavToggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="breadcrumbs">
        <a href="#">{{ title$ | async }}</a>
      </div>
    </div>
    <div class="col-xs-4 right">
      <button class="user" mat-button [matMenuTriggerFor]="menu">
        <mat-icon>account_circle</mat-icon>&nbsp;{{ firstName$ | async }}&nbsp;{{ lastName$ | async }}
      </button>
    </div>
  </div>
</mat-toolbar>


<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="signOut()">
    <span translate>auth.common.signOut</span>
  </button>
</mat-menu>
