export class NetGross {
  public net: number;
  public gross: number;

  constructor(netGross: any = null) {
    this.net = 0;
    this.gross = 0;

    if (netGross) {
      this.net = netGross.net;
      this.gross = netGross.gross;
    }
  }
}
