<div class="sidebar">


  <div class="logo">
    <h1 *ngIf="(site$ | async).name !== 'dynamix'" routerLink="dashboard" >{{ (site$ | async).name }}</h1>
    <mat-icon *ngIf="(site$ | async).name === 'dynamix'" mat-button routerLink="dashboard" svgIcon="dynamix"></mat-icon>
  </div>

  <mat-divider></mat-divider>

  <dy-site-picker></dy-site-picker>

  <nav>

    <mat-nav-list role="list">

      <mat-list-item role="listitem" routerLink="dashboard" routerLinkActive="active">
        <mat-icon mat-list-icon>apps</mat-icon>
        <span translate>dashboard.pages.all.title</span>
      </mat-list-item>

      <mat-list-item role="listitem" routerLink="documents" routerLinkActive="active"
                     *ngIf="hasDocumentsScope$ | async">
        <mat-icon mat-list-icon>folder</mat-icon>
        <span translate>documents.pages.all.title</span>
      </mat-list-item>

      <mat-list-item role="listitem" routerLink="documents/create" routerLinkActive="active"
                     *ngIf="hasDocumentsScope$ | async">
        <mat-icon mat-list-icon>note_add</mat-icon>
        <span translate>documents.pages.create.title</span>
      </mat-list-item>


      <mat-list-item role="listitem" routerLink="projects" routerLinkActive="active" *ngIf="hasProjectsScope$ | async">
        <mat-icon mat-list-icon>home</mat-icon>
        <span translate>projects.pages.all.title</span>
      </mat-list-item>


      <mat-list-item role="listitem" routerLink="contacts" routerLinkActive="active" *ngIf="hasContactsScope$ | async">
        <mat-icon mat-list-icon>people</mat-icon>
        <span translate>contacts.pages.all.title</span>
      </mat-list-item>

      <mat-list-item role="listitem" routerLink="balance" routerLinkActive="active" *ngIf="hasBalanceScope$ | async">
        <mat-icon mat-list-icon>account_balance</mat-icon>
        <span translate>cashBalances.pages.all.title</span>
      </mat-list-item>

      <mat-list-item role="listitem" routerLink="tags" routerLinkActive="active" *ngIf="hasTagsScope$ | async">
        <mat-icon mat-list-icon>label</mat-icon>
        <span translate>tags.pages.all.title</span>
      </mat-list-item>

      <h3 mat-subheader>Administracja</h3>

      <mat-list-item role="listitem" routerLink="users" routerLinkActive="active" *ngIf="hasUsersScope$ | async">
        <mat-icon mat-list-icon>group</mat-icon>
        <span translate>users.pages.all.title</span>
      </mat-list-item>

      <mat-list-item role="listitem" routerLink="sites" routerLinkActive="active" *ngIf="hasSiteScope$ | async">
        <mat-icon mat-list-icon>settings</mat-icon>
        <span translate>site.pages.all.title</span>
      </mat-list-item>

      <mat-list-item role="listitem" routerLink="changes-history" routerLinkActive="active" *ngIf="hasHistoryChangesScope$ | async">
        <mat-icon mat-list-icon>settings</mat-icon>
        <span translate>changesHistory.pages.all.title</span>
      </mat-list-item>

      <mat-list-item role="listitem" routerLink="logs" routerLinkActive="active" *ngIf="hasLogsScope$ | async">
        <mat-icon mat-list-icon>fingerprint</mat-icon>
        <span translate>common.logs</span>
      </mat-list-item>

    </mat-nav-list>


  </nav>

</div>

