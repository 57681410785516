export class File {
  public extension: string;
  public contentType: string;
  public src: string;
  public size: number;

  constructor(file: any = null) {

    this.extension = "";
    this.src = "";
    this.contentType = "";
    this.size = 0;

    if (file) {
      this.extension = file.extension;
      this.src = file.src;
      this.contentType = file.contentType;
      this.size = file.size;
    }
  }

  isPdf(): boolean {
    return this.extension === 'pdf';
  }

  isXlsx(): boolean {
    return this.extension === 'xlsx';
  }

  exists(): boolean {
    return this.src !== '';
  }
}


