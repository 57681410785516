import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';

import {AuthGuard, UnAuthGuard} from '../core/guards/auth.guard';
import {AuthApiService} from './services/auth-api.service';
import {SignInComponent} from './pages/sign-in/sign-in.component';
import {RouterModule, Routes} from '@angular/router';
import {CoreModule} from '../core/core.module';
import {SignUpComponent} from './pages/sign-up/sign-up.component';


const routes: Routes = [
  {path: 'sign-in', data: {title: 'Logowanie'}, component: SignInComponent, canActivate: [UnAuthGuard]},
  {path: 'sign-up', data: {title: 'Rejestracja'}, component: SignUpComponent, canActivate: [UnAuthGuard]},
];

@NgModule({
  imports: [
    SharedModule,
    CoreModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    SignInComponent,
    SignUpComponent,
  ],
  providers: [
    AuthGuard,
    UnAuthGuard,
    AuthApiService,
  ],
  exports: [
    SignInComponent,
  ],
})
export class AuthModule {
}
