import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Title} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {AppState} from '../../app.state';
import {getSite} from '../../auth/store/auth.selectors';
import {LayoutActions} from './layout.actions';
import {map, tap, withLatestFrom} from 'rxjs/operators';

@Injectable()
export class LayoutEffects {

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private titleService: Title) {
  }

  @Effect({dispatch: false})
  titleChange$ = this.actions$.pipe(
      ofType(LayoutActions.SET_TILE),
      withLatestFrom(this.store.select(getSite)),
      map(([action, site]: [any, any]) => {
        let title = action.payload.title;

        if (site.name.length > 0) {
          title = `${title} - ${site.name}`;
        }

        this.titleService.setTitle(title);
      }),
  );

}
