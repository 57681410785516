import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {Router} from '@angular/router';
import {getSite, getToken} from '../auth/store/auth.selectors';
import {AuthState} from '../auth/store/auth.state';
import {Site} from '../users/models';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  protected token: string = null;
  protected site: Site;

  constructor(private router: Router, private store: Store<AppState>) {
    this.store.select(getToken).subscribe((token: string) => this.token = token);
    this.store.select(getSite).subscribe((site: Site) => this.site = site);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headers = {};

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token})`;
    }

    if (this.site && this.site.exists()) {
      headers['Site-Id'] = this.site.id;
    }

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request);

  }

}

// .pipe(catchError((error) => {
//
//   //@todo 400 handled
//
//   if (error.status === 500) {
//     console.info('NOTIFICATION 500');
//   }
//
//   if (error.status === 401) {
//     console.info('NOTIFICATION 401 NOT LOGGED');
//
//     this.store.dispatch({
//       type: AuthActions.SIGN_OUT,
//       payload: {redirect: true}
//     });
//
//   }
//
//   console.log('[ERROR]', error);
//   return of(error);
// }) as any);

// import {Injectable} from '@angular/core';
// import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
// import {Observable} from 'rxjs/Observable';
//
//
// @Injectable()
// export class HttpErrorInterceptor implements HttpInterceptor {
//
//
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//
//     console.log('intercepted request ... ');
//
// // Clone the request to add the new header.
// //     const authReq = req.clone({headers: req.headers.set('headerName', 'headerValue')});
//
//     // console.log('Sending request with new header now ...');
//
// //send the newly created request
//     return next.handle(req).catch((error, caught) => {
// //intercept the respons error and displace it to the console
//         console.log('Error Occurred');
//         console.log(error);
// //return the error to the method that called it
//         return Observable.throw(error);
//       }) as any;
//   }
//
//
//   // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//   //
//   //
//   //
//   //
//   //   console.log('intercept');
//   //
//   //   return next.handle(req).do((event: HttpEvent<any>) => {
//   //     console.log('WAT', event);
//   //   }, (err: any) => {
//   //     if (err instanceof HttpErrorResponse) {
//   //       console.error('ERROR', err);
//   //     }
//   //   });
//   //
//   // }
// }
