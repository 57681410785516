import {NgModule} from '@angular/core';
import {HeaderComponent} from './components/header/header.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {SharedModule} from '../shared/shared.module';
import {MainComponent} from './components/main/main.component';
import {FooterComponent} from './components/footer/footer.component';
import { SitePickerComponent } from './components/site-picker/site-picker.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    MainComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent
  ],
  declarations: [HeaderComponent, SidebarComponent, MainComponent, FooterComponent, SitePickerComponent]
})
export class CoreModule {
}
