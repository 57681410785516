import {Component, Input} from '@angular/core';

@Component({
  selector: 'mo-errors',
  template: `
    <div class="mo-errors" *ngIf="errors && errors.length > 0">
      <p *ngFor="let error of errors">
        <mat-icon>error_outline</mat-icon>
        <span>{{error}}</span>
      </p>
    </div>
  `,
  styleUrls: ['./errors.component.less']
})
export class ErrorsComponent {
  @Input() errors: string[] = [];
}
