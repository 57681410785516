export class Kpkw {
  public id: number;
  public title: string;
  public gross: number;
  public dateIssued: Date;
  public isWithdraw: boolean;

  constructor(kpkw: any = null) {

    const today = new Date();

    this.id = 0;
    this.gross = 0;
    this.title = '';
    this.dateIssued = today;
    this.isWithdraw = false;

    if (kpkw) {
      this.id = kpkw.id;
      this.gross = kpkw.gross;
      this.title = kpkw.title;
      this.dateIssued = new Date(kpkw.dateIssued);
      this.isWithdraw = kpkw.isWithdraw;
    }
  }

  private toTime(date: Date = null) {
    return date ? Math.round(date.getTime() / 1000) : null;
  }

  serialize(): any {
    return {
      id: this.id,
      title: this.title,
      isWithdraw: this.isWithdraw,
      gross: this.gross,
      dateIssued: this.toTime(this.dateIssued)
    };

  }

}


