import {Component} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.state';
import {
  hasBalanceScope,
  hasContactsScope,
  hasDocumentsScope,
  hasProjectsScope,
  hasUsersScope,
  hasLogsScope,
  hasTagsScope,
  getSite, hasSiteScope, hasHistoryChangesScope,
} from '../../../auth/store/auth.selectors';
import {Site} from '../../../users/models';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent {

  public hasProjectsScope$: Observable<boolean>;
  public hasDocumentsScope$: Observable<boolean>;
  public hasContactsScope$: Observable<boolean>;
  public hasBalanceScope$: Observable<boolean>;
  public hasUsersScope$: Observable<boolean>;
  public hasLogsScope$: Observable<boolean>;
  public hasTagsScope$: Observable<boolean>;
  public hasSiteScope$: Observable<boolean>;
  public hasHistoryChangesScope$: Observable<boolean>;
  public site$: Observable<Site>;


  constructor(private store: Store<AppState>) {
    this.hasProjectsScope$ = this.store.select(hasProjectsScope);
    this.hasDocumentsScope$ = this.store.select(hasDocumentsScope);
    this.hasContactsScope$ = this.store.select(hasContactsScope);
    this.hasBalanceScope$ = this.store.select(hasBalanceScope);
    this.hasUsersScope$ = this.store.select(hasUsersScope);
    this.hasLogsScope$ = this.store.select(hasLogsScope);
    this.hasTagsScope$ = this.store.select(hasTagsScope);
    this.hasSiteScope$ = this.store.select(hasSiteScope);
    this.hasHistoryChangesScope$ = this.store.select(hasHistoryChangesScope);
    this.site$ = this.store.select(getSite);
  }

}
