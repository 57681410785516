import {AuthState} from './auth.state';
import {AuthActions} from './auth.actions';

export function authReducer(state: AuthState = new AuthState(), action: any) {
  switch (action.type) {
    case AuthActions.SET_AUTH:
      return {...state, ...action.payload};
    case AuthActions.SIGN_IN_SUCCESS:
      return {...state, ...action.payload};
    case AuthActions.SET_SITE:
      return {...state, ...action.payload};
    case AuthActions.SIGN_OUT:
      return {};
    default:
      return state;
  }
}

