import {FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import { MatDialogConfig } from '@angular/material/dialog';
import {Subscription} from 'rxjs';

export abstract class FormComponent {

  public formGroup: FormGroup;
  public isLoaded: boolean = false;
  public errors: string[] = [];

  public subscriptions: Subscription[] = [];

  public matDialogConfig(data: any = {}, mode: string = 'default', panelClass: string = ''): MatDialogConfig {

    const options = <MatDialogConfig>{
      width: '600px',
      height: '100%',
      maxHeight: '80%',
      maxWidth: '80%',
      hasBackdrop: true,
      autoFocus: true,
      position: {top: '10%'},
      panelClass
    };

    if (mode === 'fullscreen') {
      options.width = '100%';
      options.height = '100%';
      options.maxHeight = '100%';
      options.maxWidth = '100%';
      options.position = {top: '0'};
    }

    if (mode === 'halfscreen') {
      options.width = '50%';
      options.height = '100%';
      options.maxHeight = '100%';
      options.maxWidth = '100%';
      options.position = {top: '0', right: '0'};
    }

    const result = <MatDialogConfig>{...options, ...data};
    return result;
  }


  protected load(): void {
    this.isLoaded = false;
    this.errors = [];
  }

  protected loaded(): void {
    this.isLoaded = true;
  }

  protected onError(response: HttpErrorResponse): void {
    this.isLoaded = true;

    // console.error('error', response);

    // console.log(response);

    let errors = [];
    if (response && response.error && response.error.errors) {
      errors = response.error.errors;
    }

    if (response && response.error && response.error instanceof Blob){
      // console.log(response, response.message);
      errors = ['Coś poszło nie tak'];

      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        this.errors = JSON.parse(e.srcElement['result']).errors;
      });
      reader.readAsText(response.error);

    }

    this.errors = errors;

  }

  protected addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  protected unsubscribe(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());

  }



}
