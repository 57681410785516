import {Contact} from '../../contacts/models';
import {Project} from '../../projects/models';
import {DocumentType} from './documentType.model';
import {Invoice} from './invoice.model';
import {Kpkw} from './kpkw.model';
import {BalanceReport} from './balanceReport.model';
import {ReInvoice} from './reInvoice.model';
import {File} from '../../shared/models';
import {Tag} from '../../tags-manager/models';
import {Custom} from './custom.model';

export class Document {

  public id: number;

  public name: string;
  public meta: any;
  public uiMeta: any;

  public typeName?: string;
  public type: DocumentType;

  public projectId: number;
  public project: Project;

  public contactId: number;
  public contact: Contact;

  public isSelected: boolean;
  public isDraft: boolean;
  public isDeleted: boolean;
  public isReInvoiced: boolean;
  public isSplitPayment: boolean;

  public dateIssued: number;

  public correctedDocumentId: number;

  public file: File | null;

  public tags: Tag[];
  public tagIds: number[];

  public messages: string[];

  public paymentMethod: string;

  static readonly MESSAGE_CONTACT_CREATED = "MESSAGE_CONTACT_CREATED";
  private isEscrowAccount: boolean;

  constructor(document: any = null) {
    this.id = 0;
    this.type = new DocumentType();
    this.projectId = 0;
    this.contactId = 0;
    this.name = '';
    this.meta = null;
    this.uiMeta = null;
    this.dateIssued = 0;
    this.contact = new Contact();
    this.project = new Project();
    this.typeName = '';
    this.isSelected = false;
    this.isDraft = false;
    this.isDeleted = false;
    this.isReInvoiced = false;
    this.isSplitPayment = false;
    this.isEscrowAccount = false;
    this.correctedDocumentId = 0;
    this.file = new File();
    this.tags = [];
    this.tagIds = [];
    this.messages = [];
    this.paymentMethod = '';

    if (document) {
      this.id = document.id;
      this.type = document.type;
      this.projectId = document.projectId;
      this.contactId = document.contactId;
      this.name = document.name;
      this.meta = document.meta;
      this.uiMeta = document.uiMeta;
      this.dateIssued = document.dateIssued;
      this.contact = new Contact(document.contact);
      this.project = document.project;
      this.typeName = document.typeName;
      this.isDraft = document.isDraft;
      this.isDeleted = document.isDeleted;
      this.isReInvoiced = document.isReInvoiced;
      this.isSplitPayment = document.isSplitPayment;
      this.isEscrowAccount = document.isEscrowAccount;
      this.correctedDocumentId = document.correctedDocumentId;
      this.messages = document.messages;
      this.paymentMethod = document.paymentMethod;

      if (document.meta) {
        if (this.isInvoice()) {
          this.meta = new Invoice(document.meta);
        }

        if (this.isCdcw()) {
          this.meta = new Kpkw(document.meta);
        }

        if (this.isBalanceReport()) {
          this.meta = new BalanceReport(document.meta);
        }

        if (this.isCustom()) {
          this.meta = new Custom(document.meta);
        }
      }

      if(document.tags) {
        this.tags = document.tags.map((tag: Tag) => new Tag(tag));
        this.tagIds = this.tags.map((tag: Tag) => tag.id);
      }

      if (document.file) {
        this.file = new File(document.file);
      }

      if (document.uiMeta) {
        this.uiMeta = document.uiMeta;
      }
    }
  }

  setIsReInvoiced(isReInvoiced: boolean): void {
    this.isReInvoiced = isReInvoiced;
  }

  setIsSplitPayment(isSplitPayment: boolean): void {
    this.isSplitPayment = isSplitPayment;
  }

  setIsEscrowAccount(isEscrowAccount: boolean): void {
    this.isEscrowAccount = isEscrowAccount;
  }

  setTagIds(tagsIds: number[]) {
    // aktualizujemy tylko liste idkow a nie tagi
    this.tagIds = tagsIds;
  }

  setName(name: string): void {
    this.name = name;
  }

  setContactId(contactId: number): void {
    this.contactId = contactId;
  }

  setProjectId(projectId: number): void {
    this.projectId = projectId;
  }


  setType(documentType: DocumentType) {
    this.type = documentType;
    this.typeName = documentType.name;
  }

  setProject(project: Project) {
    this.project = project;
  }

  setContact(contact: Contact) {
    this.contact = contact;
  }

  getInvoice(): Invoice {
    return this.meta;
  }

  setSelection(isSelected: boolean): void {
    this.isSelected = isSelected;
  }

  serialize(data: any = {}): any {
    let meta = this.meta;

    if (this.isInvoice()) {
      const invoice = new Invoice(meta);
      meta = invoice.serialize();
    }

    if (this.isCdcw()) {
      const kpkw = new Kpkw(meta);
      meta = kpkw.serialize();
    }

    if (this.isBalanceReport()) {
      const balanceReport = new BalanceReport(meta);
      meta = balanceReport.serialize();
    }

    if (this.isReInvoice()) {
      const reInvoice = new ReInvoice(meta);
      meta = reInvoice.serialize();
    }

    if (this.isCustom()) {
      const custom = new Custom(meta);
      meta = custom.serialize();
    }

    const resp = {
      'id': this.id,
      'typeName': this.typeName,
      'name': this.name,
      'contactId': this.contactId,
      'projectId': this.projectId,
      'meta': meta,
      'correctedDocumentId': this.correctedDocumentId,
      'tagIds': this.tagIds,
      'isReInvoiced': this.isReInvoiced,
      'isSplitPayment': this.isSplitPayment,
      'isEscrowAccount': this.isEscrowAccount
    };

    return {...resp, ...data};
  }

  setMeta(meta: any): void {
    this.meta = meta;
  }

  isReInvoice(): boolean {
    return this.type.name === DocumentType.RE_INVOICE;
  }

  isPdf(): boolean {
    return this.file.isPdf();
  }

  isXlsx(): boolean {
    return this.file.isXlsx();
  }

  hasMeta(): boolean {
    return !!this.meta;
  }

  isCdcw(): boolean {
    return this.type.name === DocumentType.CASH_DEPOSIT_CASH_WITHDRAW;
  }

  isBalanceReport(): boolean {
    return this.type.name === DocumentType.BALANCE_REPORT;
  }

  isIncomeInvoice(): boolean {
    return this.type.name === DocumentType.INCOME_INVOICE;
  }

  isCostInvoice(): boolean {
    return this.type.name === DocumentType.COST_INVOICE;
  }

  isCustomCostDocument(): boolean {
    return this.type.name === DocumentType.CUSTOM_COST_DOCUMENT;
  }

  canBeReInvoiced(): boolean {
    return this.isCostInvoice() || this.isCustomCostDocument();
  }

  isCustom(): boolean {
    return this.type.name === DocumentType.CUSTOM;
  }

  isCorrectionInvoice(): boolean {
    return this.type.name === DocumentType.INCOME_INVOICE_CORRECTION;
  }

  isInvoice(): boolean {
    return this.isIncomeInvoice() || this.isCostInvoice() || this.isCustomCostDocument() || this.isCorrectionInvoice();
  }

  isFinancialDocument(): boolean {
    return this.isInvoice() || this.isCdcw();
  }

  exists(): boolean {
    return (this.id && this.id > 0);
  }

}
