import {DocumentType} from './documentType.model';
import {DateRange} from '../../shared/components/date-range-picker/dateRange.model';
import {PaymentMethod} from './paymentMethod.model';


export class DocumentsSearch {
  public page: number;
  public documentTypeNames: string[];
  public contactIds: number[];
  public projectIds: number[];
  public tagIds: number[];
  public searchPhrase: string;
  public orderBy: string;
  public orderDirection: string;
  public limit: number;
  public dateRange: DateRange;
  public isPayed: boolean;
  public isDraft: boolean;
  public paymentMethods: string[];

  constructor(documentsSearch: any = null) {

    //@todo change name to one from backend?

    const date = new Date();
    const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.page = 1;
    this.documentTypeNames = [
      DocumentType.INCOME_INVOICE,
      DocumentType.COST_INVOICE,
      DocumentType.CUSTOM_COST_DOCUMENT,
      DocumentType.CUSTOM,
      DocumentType.BALANCE_REPORT,
      DocumentType.CASH_DEPOSIT_CASH_WITHDRAW,
      DocumentType.RE_INVOICE,
      DocumentType.INCOME_INVOICE_CORRECTION,
    ];
    this.contactIds = [];
    this.projectIds = [];
    this.tagIds = [];
    this.searchPhrase = '';
    this.orderBy = 'dateIssued';
    this.orderDirection = 'desc';
    this.dateRange = new DateRange(firstDayOfCurrentMonth, lastDayOfCurrentMonth);
    this.isPayed = null;
    this.isDraft = null;
    this.limit = 50;
    this.paymentMethods = [
      PaymentMethod.CASH,
      PaymentMethod.TRANSFER,
      PaymentMethod.CREDIT_CARD,
    ];


    if (documentsSearch) {
      this.page = documentsSearch.page;
      this.documentTypeNames = documentsSearch.documentTypeNames;

      this.contactIds = documentsSearch.contactIds;
      this.projectIds = documentsSearch.projectIds;
      this.dateRange = new DateRange(documentsSearch.dateRange.fromDate, documentsSearch.dateRange.toDate);
      this.searchPhrase = documentsSearch.searchPhrase;
      this.orderBy = documentsSearch.orderBy;
      this.orderDirection = documentsSearch.orderDirection;
      this.limit = documentsSearch.limit;

      if (this.isBool(documentsSearch.isPayed)) {
        this.isPayed = documentsSearch.isPayed;
      }

      if (this.isBool(documentsSearch.isDraft)) {
        this.isDraft = documentsSearch.isDraft;
      }

      if (documentsSearch.tagIds) {
        this.tagIds = documentsSearch.tagIds;
      }

      if (documentsSearch.paymentMethods) {
        this.paymentMethods = documentsSearch.paymentMethods;
      }
    }
  }

  private toTime(date: Date = null) {
    return date ? Math.round(date.getTime() / 1000) : null;
  }


  public setContactIds(contactIds: number[]) {
    this.contactIds = contactIds;
  }

  public setTagIds(tagIds: number[]) {
    this.tagIds = tagIds;
  }

  public setProjectIds(projectIds: number[]) {
    this.projectIds = projectIds;
  }

  serialize(): any {
    let response: any = {
      page: this.page,
      limit: this.limit,
      typeNames: this.documentTypeNames.join(','),
      paymentMethods: this.paymentMethods.join(','),
      contactIds: this.contactIds.join(','),
      tagIds: this.tagIds.join(','),
      orderBy: this.orderBy,
      orderDirection: this.orderDirection,
      projectIds: this.projectIds.join(','),
      searchPhrase: this.searchPhrase,
      dateFrom: this.toTime(this.dateRange.fromDate),
      dateTo: this.toTime(this.dateRange.toDate),
    };

    if (this.isBool(this.isPayed)) {
      response.isPayed = this.isPayed;
    }

    if (this.isBool(this.isDraft)) {
      response.isDraft = this.isDraft;
    }

    return response;

  }

  private isBool(val: any) {
    return typeof (val) === 'boolean';
  }
}
